import React, { useReducer, useContext, createContext, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";

const AuthStateContext = createContext();
const AuthDispatchContext = createContext();

// returns user information saved in context
export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  const getUserInformation = async () => {
    let authInfo = {};
    try {
      const authenticatedUser = await Auth.currentAuthenticatedUser();
      authInfo = {
        email: authenticatedUser.attributes.email,
        commerceID: authenticatedUser.attributes["custom:commerceID"],
        groups:
          authenticatedUser.signInUserSession?.accessToken.payload[
            "cognito:groups"
          ],
      };
    } catch {
      authInfo = {};
    }
    dispatch(authInfo);
  };

  useEffect(() => {
    getUserInformation();
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      if (event === "signOut") {
        dispatch(initialState);
        return;
      }
      getUserInformation();
    });
  }, []);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const initialState = {
  groups: [],
  email: "",
  commerceID: "",
};

const AuthReducer = (_state, action) => {
  return {
    groups: action.groups ? action.groups : [],
    email: action.email ? action.email : "",
    commerceID: action.commerceID ? action.commerceID : "",
  };
};
