/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_POOL_WEB_CLIENT_ID,
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
};


export default awsconfig;
