import Amplify, { I18n } from "aws-amplify";
import awsconfig from "./awsconfig";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import translations from "./translations";

import Router from "./Router";

import { AuthProvider } from "./providers/auth-context";

import { ThemeProvider, styled, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import Navigation from "./components/Navigation";
import FlagProvider from "@unleash/proxy-client-react";

Amplify.configure(awsconfig);

I18n.setLanguage("es");

I18n.putVocabularies(translations);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  //alignItems: 'center',
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  const unleashConfig = {
    url: "https://cloud.leal.co/unleash-proxy/proxy",
    clientKey: process.env.REACT_APP_UNLEASH_PROXY_KEY,
    refreshInterval: 15,
    appName: "default",
    environment: "dev",
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#ffd600",
        light: "#ffff52",
        dark: "#c7a500",
      },
      secondary: {
        main: "#212121",
        light: "#484848",
        dark: "#000000",
      },
    },
  });

  console.log("api url:", process.env.REACT_APP_API_URL);

  return (
    <Authenticator loginMechanisms={["email"]}>
      {() => (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <ThemeProvider theme={theme}>
          <FlagProvider config={unleashConfig}>
              <AuthProvider>
                <Navigation />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                  <DrawerHeader />
                  <Router />
                </Box>
              </AuthProvider>
              </FlagProvider>
          </ThemeProvider>
        </Box>
      )}
    </Authenticator>
  );
}

export default App;