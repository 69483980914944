import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";

const Home = lazy(() => import("./pages/index"));
const Users = lazy(() => import("./pages/users/index"));
const CreateUser = lazy(() => import("./pages/users/create"));
const Bonus = lazy(() => import("./pages/bonus/index"));
const Segments = lazy(() => import("./pages/segment/index"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading open={true} />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="users">
            <Route index element={<Users />} />
            <Route path="create" element={<CreateUser />} />
          </Route>
          <Route path="segments">
            <Route index element={<Segments />} />
          </Route>
          <Route path="bonus">
            <Route index element={<Bonus />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
