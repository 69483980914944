import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function Loading({ open }) {
  return (
    <Backdrop style={{ zIndex: 10000 }} sx={{ color: "#ffd600" }} open={open}>
      <CircularProgress color="inherit" size={68} />
    </Backdrop>
  );
}

export default Loading;
